<template>
  <v-container fill-height >
    <v-row  align="center" justify="center">
      <v-col align="center">
        <v-img max-width="600" :src="img"> </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import img from "@/assets/images/main/maintenance.png";
export default {
  data() {
    return {
      img,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped></style>
