//import axios from "axios";
//import config from "../config";

const store = {
  state: {
    notificationId: null,
  },

  getters: {
    notificationId: (state) => {
      return state.notificationId;
    },
  },

  mutations: {
    saveNotification: (state, data) => {
      state.notificationId = data;
    },
  },

  actions: {},
};

export default store;
