//import axios from "axios";
//import config from "../config";

const store = {
  state: {
    commerce: JSON.parse(localStorage.getItem("commerce")) || [],
    products: JSON.parse(localStorage.getItem("store")) || [],
    loaded: JSON.parse(localStorage.getItem("store")) ? 1 : 0,
    error: false,
    message: "",
  },

  getters: {
    isLoad: (state) => {
      return state.loaded;
    },

    error: (state) => {
      return state.error;
    },

    commerce: (state) => {
      return state.commerce;
    },

    cart: (state) => {
      return state.products;
    },

    message: (state) => {
      return state.message;
    },
  },

  mutations: {
    addToCart: (state, product) => {
      let commerce = product.commerce;
      let item = product.item;
      let cant = product.cant;

      const typeToAdde = {
        onlyPush: [5, 6, 7],
      };

      if (
        state.commerce.length === 0 ||
        state.commerce[0].id === commerce[0].id
      ) {
        state.error = false;
        state.commerce = commerce;

        //find the product in the products list
        let productItem = state.products.find(
          (product) => product.item.id === item.id
        );

        //AGREGAR PRODUCTO POR PRIMERA VEZ

        if (!productItem) {
          state.products.push({
            item,
            cant,
          });

          state.loaded = 1;
          localStorage.setItem("store", JSON.stringify(state.products));
          localStorage.setItem("commerce", JSON.stringify(state.commerce));
        }

        
        if (productItem) {
          if (typeToAdde.onlyPush.some((obj) => obj == item.type)) {
            state.products.push({
              item,
              cant,
            });
          } else {
            state.products.forEach(function (v) {
              if (v.item.id === item.id) {
                v.cant = v.cant + cant;
              }
            });
          }

          localStorage.setItem("store", JSON.stringify(state.products));
          localStorage.setItem("commerce", JSON.stringify(state.commerce));
        }
      }
      if (state.commerce && state.commerce != commerce) {
        state.error = true;
      }
    },

    deleteFromCart: (state, productId) => {
      let productIndex = state.products.findIndex(
        (product) => product.item.id === productId
      );

      state.products.splice(productIndex, 1);

      localStorage.setItem("store", JSON.stringify(state.products));
      localStorage.setItem("commerce", JSON.stringify(state.commerce));

      if (state.products.length === 0) {
        state.commerce = [];
        localStorage.removeItem("store");
        localStorage.removeItem("commerce");
      }
      let v = JSON.stringify(state.products);
      if (v === "[]") {
        state.loaded = 0;
      }
    },

    clearCart: (state) => {
      state.loaded = 0;
      state.commerce = [];
      state.products = [];
      state.error = false;
      localStorage.removeItem("store");
      localStorage.removeItem("commerce");
    },

    loadChange: (state, data) => {
      state.loaded = data;
    },

    acceptError: (state) => {
      state.error = false;
    },
  },

  actions: {},
};

export default store;
