import { createClient } from "@supabase/supabase-js";

const currentUrl = window.location.href;

const url = currentUrl.includes("https://www.papedidelivery.com/");

 const config = url  ===  false ? { url: "https://gmqkansrrqywlfrzadoo.supabase.co" ,key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdtcWthbnNycnF5d2xmcnphZG9vIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU0ODY2MDgsImV4cCI6MjAwMTA2MjYwOH0.JQH4PHG_TfV8zSjAShkOdphPxTXdWZJds62ZV1Czbj4" } : { url: "https://dmcdivaawaovhsvxqctj.supabase.co" ,key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRtY2RpdmFhd2FvdmhzdnhxY3RqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTYwMDMyODgsImV4cCI6MjAxMTU3OTI4OH0.gsGbEZ6OpkeyY1f58lFSgWUr_4PHHet1zo1LkdI0fdc" };


 const supabase = createClient(
    config.url,
  config.key,
  {
    realtime: {
        params: {
            eventsPerSecond: 10,
        },
    },
}
);

export default supabase;
