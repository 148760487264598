import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import auth from "./auth"
import category from "./category"
import store from "./store"
import map from "./map"
import order from "./order"
import notification from "./notification"


export default new Vuex.Store({
    modules: [
        auth,
        category,
        store,
        map,
        order,
        notification
    ]
})
