<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="4" align="center">
        <v-card class="pa-4">
          <div>
            <v-img :src="img" width="250px"/>
          </div>
          <div class="text-h5 font-weight-bold">Descargar App</div>
          <div class="mt-2">
            Dale click a descargar y obtén la última versión de nuestra aplicación para comercios.

          </div>
          <div class="mt-4 mb-3"><v-btn color="primary" @click="donwLoad" :loading="loading" :href="`https://dmcdivaawaovhsvxqctj.supabase.co/storage/v1/object/public/apps-assets/app-commerces/app/v1.0/Papedi%20Comercio.apk`" target="_blank">Descargar</v-btn></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import img from "@/assets/images/main/app-download.png";
export default {
  data() {
    return {
      img,
      loading: false,
    };
  },

  mounted() {},

  methods: {

    donwLoad() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        
      }, 500);

    }


  },
};
</script>

<style scoped></style>
