import axios from 'axios';
import config from "../config";
import supabase from "../lib/supabaseClient";
const store = {
  state: {
    user: null,
    token: localStorage.getItem("sb-gmqkansrrqywlfrzadoo-auth-token") || null,
    mensaje: "",
  },

  getters: {
    getToken(state) {
      return state.token;
    },
  },

  mutations: {
    savetoken(state, data) {
      state.token = data;
    },
  },

  actions: {
    async signInWithGoogle() {
      const { data, error } = await supabase.auth.signInWithOAuth({ provider: "google",});
      if (error) {
        console.error("Error signing in with Google:", error.message);
        this.loading = false;
      }
      if (data) {
        console.log(data);
        console.log("aqui ")
      }
    },

    registerUserAction(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/user/new/`, data)
          .then((response) => {
            console.log(response)
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    userValidateAction(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/user/validate`, data)
          .then((response) => {            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },


  },
};

export default store;
