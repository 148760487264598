import axios from "axios";
import config from "../config";

const store = {
  state: {
    categories: {},
    message: null,
    categorieCommerces: [],
    commerceItems: [],
    commerceTop: [],
    product: [],
    promotions: [],
  },

  getters: {
    getCategories(state) {
      return state.categories;
    },
    getCategorieCommerces(state) {
      return state.categorieCommerces;
    },
    getCommercesItems(state) {
      return state.commerceItems;
    },
    getCommerceTop(state) {
      return state.commerceTop;
    },

    getProduct(state) {
      return state.product;
    },

    getPromotions(state) {
      return state.promotions;
    },
  },

  mutations: {
    saveCategories(state, data) {
      state.categories = data;
    },

    savePromotions(state, data) {
      state.promotions = data;
    },

    saveCategoriesCommerces(state, data) {
      state.categorieCommerces = data;
    },

    saveCommerceItems(state, data) {
      data.items.sort(() => Math.random() - 0.5)
      state.commerceItems = data;
    },

    saveTopCommerces(state, data) {
      state.commerceTop = data;
    },

    saveProduct(state, data) {
      state.product = data;  
    },
  },

  actions: {
    //Llamar categorias

    categoryList({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/categories/`)
          .then((response) => {
            commit("saveCategories", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // LLamar Banners de promociones

    promotionsBanners({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/promotions`)
          .then((response) => {
            commit("savePromotions", response.data.data.promotions);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

      // LLamar Banners de promociones por categoria

      categoryBanners({ commit }, category) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${config.url}/promotions?category=${category}`)
            .then((response) => {
              commit("savePromotions", response.data.data.promotions);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },

    //Llamar los comercios por categoria

    categoryCommerces({ commit }, category) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/commerces?category=${category}`)
          .then((response) => {
            commit("saveCategoriesCommerces", response.data.data.items.sort(() => Math.random() - 0.5));
            resolve(response.data.service_status);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //Llamar productos por catedoria y comercio

    productStoreCategory({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/commerce?name=${data.name}&category=${data.id}`)
          .then((response) => {
            commit("saveCommerceItems", response.data.data);
            resolve(response.data.service_status);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Llamar todos los productos de un comercio

    productByStore({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/commerce?name=${id}`)
          .then((response) => {
            commit("saveCommerceItems", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Informacion de un solo producto

    infoProduct(context, value) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/product?value=${value}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // top commerces

    topCommerces({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/top/commerces`)
          .then((response) => {
            commit("saveTopCommerces", response.data.data.top_commerces);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Llamar un solo producto
    onlyProduct({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/product?value=${data}`)
          .then((response) => {
            commit("saveProduct", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
