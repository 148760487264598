import bus from "./../event/bus";
import axios from "axios";
import config from "../config";

const store = {
  state: {
    orderCart: null,
    order: localStorage.getItem("order") || null,
    orderStatus: null,
    priceDolar: null,
    extraData: null,
    newCartOrder: [],
    schemaOrder: "",
    deliveryValue: 0,
  },

  getters: {
    orderCart: (state) => {
      return state.orderCart;
    },

    getDolar: (state) => {
      return state.priceDolar;
    },

    order: (state) => {
      return state.order;
    },

    orderStatus: (state) => {
      return state.orderStatus;
    },

    extraData: (state) => {
      return state.extraData;
    },

    newCartOrder: (state) => {
      return state.newCartOrder;
    },

    schemaOrder: (state) => {
      return state.schemaOrder;
    },
  },

  mutations: {
    saveOrder: (state, order) => {
      state.order = order;
    },

    saveExtraData: (state, data) => {
      state.extraData = data;
    },

    saveschema: (state, data) => {
      state.schemaOrder = data;
    },

    saveDolar: (state, data) => {
      state.priceDolar = data;
    },

    saveNewCartOrder: (state, cart) => {
      state.newCartOrder = cart;
    },

    deliveryValue(state, value) {
      state.deliveryValue = value;
    },

    deleteItemOrder: (state, productId) => {
      let productIndex = state.newCartOrder.findIndex(
        (product) => product.id === productId
      );

      let productReturnable = state.newCartOrder[productIndex].type;

      state.newCartOrder.splice(productIndex, 1);

      let newCartReturnable = state.newCartOrder.some((obj) => obj.type == 3);

      if (newCartReturnable == false && productReturnable == 3) {
        state.deliveryValue = state.deliveryValue / 2;
        state.orderCart.delivery_price = state.deliveryValue;
      }
    },

    addToNewCartOrder: (state, product) => {
      const { commerce, item } = product;
      const { id: commerceId } = commerce;
      const { id: itemId, cant } = item;
      const { commerce: orderCommerce, products: orderProducts } =
        state.orderCart;
      const { id: orderCommerceId } = orderCommerce;

      const typeToAdde = {
        onlyPush: [5, 6, 7],
      };

      if (orderCommerceId === commerceId) {
        //EL ES DE LA TIENDA

        const productItem = state.newCartOrder.find(({ id }) => id === itemId);
        const missingProducts =
          state.extraData.missingProducts.includes(itemId);
        const inStock = orderProducts.some(({ id }) => id === itemId);

        if (!missingProducts && !productItem && !inStock) {
          //editar la orden si es producto retornable
          let newCartReturnable = state.newCartOrder.some(
            (obj) => obj.type == 3
          );

          if (newCartReturnable == false && item.type == 3) {
            state.deliveryValue = state.deliveryValue + state.deliveryValue;
            state.orderCart.delivery_price = state.deliveryValue;
          }

          //AGREGAR PRODUCTO POR PRIMERA VEZ
          state.newCartOrder.push(item);
          bus.$emit("snackbar", {
            text: "Producto Cargado exitosamente",
            color: "green",
          });
        } else if (productItem && !missingProducts && !inStock) {
          //AGREGAR EVALUANDO SI ES TYPO 5

          if (typeToAdde.onlyPush.some((obj) => obj == item.type)) {
            state.newCartOrder.push(item);
          } else {
            state.newCartOrder.forEach((v) => {
              if (v.id === itemId) {
                let cantidad = v.cant;
                v.cant = cantidad + cant;
              }
            });
          }
        } else if (missingProducts) {
          //Producto Missing Disponible - (extra o ingredientes)

          let productItem = state.newCartOrder.find(
            (product) => product.id === itemId
          );       


          if (productItem) {
            if (typeToAdde.onlyPush.some((obj) => obj == item.type)) {
              state.newCartOrder.push(item);
            } else {
              state.newCartOrder.forEach((v) => {
                if (v.id === itemId) {
                  let cantidad = v.cant;
                  v.cant = cantidad + cant;
                }
              });
            }
          } else {
            state.newCartOrder.push(item);            
          }


        } else if (productItem && inStock) {
          state.newCartOrder.push(item);
          bus.$emit("snackbar", {
            text: "Este producto ya esta registrado en la orden",
            color: "error",
          });
        }
      } else {
        //EL PRODUCTO VIENE DE OTRA TIENDA
        bus.$emit("snackbar", {
          text: "Disculpe los productos nuevos deben ser del mismo local",
          color: "error",
        });
      }
    },

    orderStatus: (state, order) => {
      let extraData = order.extra_data;
      state.orderCart = order;
      state.orderStatus = order.status;

      if (state.schemaOrder == "editOrder" && state.deliveryValue > 0) {
        state.orderCart.delivery_price = state.deliveryValue;
      } else if (state.deliveryValue == 0) {
        state.deliveryValue = state.orderCart.delivery_price;
      }

      if (extraData != null) {
        state.extraData = extraData;
      } else {
        state.extraData = null;
      }

      if (!order) {
        state.orderStatus = null;
        state.order = null;
        localStorage.removeItem("order");
        localStorage.removeItem("cartOrder");
      }
    },

    endOrder(state) {
      localStorage.removeItem("order");
      state.order = null;
      state.orderCart = null;
      state.orderStatus = null;
      state.priceDolar = null;
      state.extraData = null;
      state.newCartOrder = [];
      state.schemaOrder = "";
    },
  },

  actions: {
    //Hacer orden de compra
    makeOrder(context, order) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/order/`, order)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    confirmOrder({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/order/check/`)
          .then((response) => {
            if (response.data.service_status === 0) {
              commit("orderStatus", response.data.data);
            } else if (response.data.service_status === 1) {
              commit("endOrder");
            }

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    dolarCheck({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/currency`)
          .then((response) => {
            commit("saveDolar", response.data.data.price);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    orderCancelAction({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/order/cancel`)
          .then((response) => {
            if (response.data.service_status === 0) {
              let ordersTatus = { status: 15, extraData: null };
              commit("orderStatus", ordersTatus);
            }

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    orderEditAction(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/order/update`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    mobilePayment(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/order/payment`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
