import axios from "axios";
import config from "../config";

const store = {
  state: {
    ubicationUser: {},
    mapStatus: true,
  },

  getters: {
    ubication: (state) => {
      return state.ubicationUser;
    },

    mapStatus: (state) => {
      return state.mapStatus;
    },
  },

  mutations: {
    saveUbication: (state, ubication) => {
      state.ubicationUser = ubication;
    },

    saveMapConfig: (state, status) => {
      state.mapStatus = status;
    },
  },

  actions: {

    // Validar Ubicacion
    validatePosition(context, position) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.url}/position/validate`, position, {
            mode: "no-cors",
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // Configuracion del mapa
    mapConfig({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.url}/map/config`)
          .then((response) => {
            commit("saveMapConfig", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
