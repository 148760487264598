import axios from "axios";
import setup from "./config";
import bus from "./event/bus";

axios.defaults.baseURL = setup.url;

axios.interceptors.request.use(
  (config) => {
    config.timeout = 15000;
    config.timeoutErrorMessage='timeout';


    var order = localStorage.getItem("order");
    if(order){
      config.headers["order"] = order;
    }

    return config;
  },
  (error) => {   
    return Promise.reject(error);
    
  }
);

axios.interceptors.response.use(
  (response) => {
    switch (response.status) {
      case 200:
        //if (response.data.service_status === 0) {console.log(response)}
        if (response.data.service_status === 1) {
          bus.$emit("snackbar", {
            text: response.data.message,
            color: "error",
          });         
        }
    }
    return response;
  },
  
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 500:
          if (error.response.data.service_status === 1) {
            bus.$emit("snackbar", {
              text: "Error al comunicarnos con el servidor intente mas tarde",
              color: "error",
            });
          }
          break;
        default:
      }
    }
    return Promise.reject(error);
  }




);

