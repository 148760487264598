import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)

// //views
// import HomePage from '../views/HomePage.vue';
// import UserProfile from '../views/UserProfile.vue'
// import CategoryProducts from '../views/CategoryProducts.vue'
// import CommercesViews from '../views/CommercesViews.vue'
// import ProductViews from '../views/ProductViews.vue'
// import TermAndCondition from '../views/TermAndCondition.vue'
// import TestViews from '../views/TestViews.vue'
// import VerificationLink from '../views/VerificationLink.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'


// //Components
// import CardProducts from '../components/General/CardProducts.vue'
// import ErrorPage from '../components/ErrorComponents/RouterError.vue'

// Mantenimiento
import maintenance from '../views/MaintenanceView.vue'
import DownLoadApp from '../views/DownLoadApp.vue'

const routes = [
  {
    path: '/',
    component: maintenance
  },

  {
    path: '/download',
    component: DownLoadApp
  },

  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },


  // {
  //   path: '/',
  //   component: HomePage
  // },
  // {
  //   path: '/user',
  //   component: UserProfile
  // },
  // {
  //   path: '/test-Y82KETSB',
  //   component: TestViews
  // },
  // {
  //   path: '*',
  //   component: ErrorPage
  // },
 
  // {
  //   path: '/commerce/:id',
  //   component: CommercesViews
  // },
  // {
  //   path: '/terminos-y-condiciones',
  //   component: TermAndCondition
  // },
  // {
  //   path: '/product/:id',
  //   component: ProductViews
  // },

  // {
  //   path: '/papedi-identity-access-management/activate-account/:id/user-config-valid-email/:otp',
  //   component: VerificationLink
  // },

  // {
  //   path: '/category/:id',
  //   component: CategoryProducts,
  //   children: [
  //     {
  //       path: 'commerce/:name',
  //       component: CardProducts,
  //     }
  //   ]
  // },
]



export default new VueRouter({ 
  
  routes: routes,
  mode: 'history'
});