import Vue from "vue";
import App from "@/App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import axios from "axios";
import store from "../src/store/index.js";
import * as VueGoogleMaps from "vue2-google-maps";
import VueClipboard from "vue-clipboard2";
import OneSignalVue from "onesignal-vue";
import helpers from './helpers/helper'

import "./interceptors";
import "./registerServiceWorker";
import "./assets/variables.scss"

const currentUrl = window.location.href;

const url = currentUrl.includes("https://www.papedidelivery.com/");

const config = {key: url  ===  false ? "AIzaSyA5hVFgG5orAXytpVcfiXu5D3h2tpIcP1k" :
 "AIzaSyCK8N6f5Pm2rzMCLoV9-rKscyMiXBEMXe8"};

 const plugins = {
  install() {
      Vue.helpers = helpers;
      Vue.prototype.$helpers = helpers;
  }
}



Vue.use(plugins);



Vue.use(VueClipboard);
Vue.use(OneSignalVue);
Vue.use(VueGoogleMaps, {
  load: {
    key: config.key,
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  axios,
  store,
  render: (h) => h(App),
}).$mount("#app");


